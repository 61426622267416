<template>
  <div style="height: 100%">
    <nesi-user-common :title="`二维码管理`" :url="`/user/house/index`">
      <template #button>
        <el-button type="primary" plain @click="addHouse">添加房源</el-button>
      </template>
      <template #content>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <el-table-column prop="fwbh" label="房屋编号" width="100" />
          <el-table-column label="房源二维码" width="100">
            <template #default="scope">
              <el-popover
                placement="top-start"
                :width="200"
                trigger="hover"
                v-if="scope.row.codePath && scope.row.open === 1"
              >
                <template #reference>
                  <div>
                    <qrcode-vue
                      :value="scope.row.codePath"
                      :size="45"
                      level="H"
                    />
                  </div>
                </template>
                <div>
                  <qrcode-vue
                    :value="scope.row.codePath"
                    :size="200"
                    level="H"
                  />
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="fwqzh" label="房屋权证号" width="180" />
          <el-table-column prop="fwzl" label="坐落" />
          <el-table-column label="核验码" width="130">
            <template #default="scope">
              {{ scope.row.open === 1 ? scope.row.gov_house_number : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="120" />
          <el-table-column label="操作" width="150">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                class="list-btn"
                @click="
                  transMethod(
                    scope.row,
                    btnMap.find((item) => item.id == btn).method
                  )
                "
                :key="btn.id"
                v-for="btn in scope.row.buttons"
                >{{ btnMap.find((item) => item.id == btn).text }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </nesi-user-common>
    <el-dialog
      v-model="upFormDialogVisible"
      title="房源上架"
      width="80%"
      top="7vh"
    >
      <el-scrollbar height="500px">
        <el-form
          :model="upForm"
          label-width="120px"
          ref="upFormRef"
          :rules="upFormRules"
          inline
        >
          <el-divider content-position="left">填写房屋信息</el-divider>
          <el-form-item label="小区名称" prop="communityName" class="w-p-47">
            <el-autocomplete
              v-model="upForm.communityName"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              clearable
              value-key="name"
              class="inline-input w-50"
              placeholder="请输入小区关键字"
              @select="handleCommutySelect"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item
            label="房源门牌"
            :prop="!upForm.buildingNo ? 'buildingNo' : 'roomNo'"
            class="w-p-47"
          >
            <el-input v-model="upForm.buildingNo" style="width: 50%">
              <template #append>栋</template>
            </el-input>
            <el-input v-model="upForm.roomNo" style="width: 50%">
              <template #append>室</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="房源楼层"
            :prop="
              !upForm.startFloor
                ? 'startFloor'
                : !upForm.terminationFloor
                ? 'terminationFloor'
                : 'buildingTotalFloor'
            "
            class="w-p-47"
          >
            <el-input
              v-model="upForm.startFloor"
              type="number"
              style="width: 31%"
            >
              <template #append>至</template>
            </el-input>
            <el-input
              v-model="upForm.terminationFloor"
              type="number"
              style="width: 38%"
            >
              <template #append>层，总</template>
            </el-input>
            <el-input
              v-model="upForm.buildingTotalFloor"
              type="number"
              style="width: 31%"
            >
              <template #append>层</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="房源户型"
            :prop="
              !upForm.roomNum
                ? 'roomNum'
                : !upForm.hallNum
                ? 'hallNum'
                : 'toiletNum'
            "
            class="w-p-47"
          >
            <el-input v-model="upForm.roomNum" type="number" style="width: 34%">
              <template #append>室</template>
            </el-input>
            <el-input v-model="upForm.hallNum" type="number" style="width: 33%">
              <template #append>厅</template>
            </el-input>
            <el-input
              v-model="upForm.toiletNum"
              type="number"
              style="width: 33%"
            >
              <template #append>卫</template>
            </el-input>
          </el-form-item>
          <el-form-item label="装修类型" prop="situation" class="w-p-47">
            <el-select
              v-model="upForm.situation"
              placeholder="请选择装修类型"
              @change="situationChange"
              style="width: 100%"
            >
              <el-option
                v-for="item in situationMap"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="朝向类型" prop="toward" class="w-p-47">
            <el-select
              v-model="upForm.toward"
              placeholder="请选择朝向类型"
              @change="towardChange"
              style="width: 100%"
            >
              <el-option
                v-for="item in towardMap"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="房源标签" class="w-p-47">
            <el-checkbox-group v-model="upForm.houseTagsArr">
              <el-checkbox label="精装修" name="houseTags" border />
              <el-checkbox label="独立阳台" name="houseTags" border />
              <el-checkbox label="近地铁" name="houseTags" border />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="房东要求" class="w-p-47">
            <el-checkbox-group v-model="upForm.landlordRequestArr">
              <el-checkbox label="禁宠物" name="landlordRequest" border />
              <el-checkbox label="禁烟" name="landlordRequest" border />
              <el-checkbox label="限制女生" name="landlordRequest" border />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="配套设施" class="w-p-97">
            <el-checkbox-group v-model="upForm.collocationNameArr">
              <el-checkbox name="collocationName" label="床" border />
              <el-checkbox name="collocationName" label="电视" border />
              <el-checkbox name="collocationName" label="热水器" border />
              <el-checkbox name="collocationName" label="空调" border />
              <el-checkbox name="collocationName" label="洗衣机" border />
              <el-checkbox name="collocationName" label="冰箱" border />
              <el-checkbox name="collocationName" label="厨房" border />
              <el-checkbox name="collocationName" label="卫生间" border />
              <el-checkbox name="collocationName" label="衣柜" border />
              <el-checkbox
                name="collocationName"
                label="宽带"
                border
                style="margin-left: 0"
              />
              <el-checkbox name="collocationName" label="书桌" border />
              <el-checkbox name="collocationName" label="床头柜" border />
              <el-checkbox name="collocationName" label="书架" border />
              <el-checkbox name="collocationName" label="阳台" border />
              <el-checkbox name="collocationName" label="燃气灶" border />
              <el-checkbox name="collocationName" label="微波炉" border />
              <el-checkbox name="collocationName" label="烤箱" border />
              <el-checkbox name="collocationName" label="椅子" border />
              <el-checkbox
                name="collocationName"
                label="沙发"
                border
                style="margin-left: 0"
              />
              <el-checkbox name="collocationName" label="电话" border />
              <el-checkbox name="collocationName" label="电梯" border />
              <el-checkbox name="collocationName" label="浴缸" border />
              <el-checkbox name="collocationName" label="晾衣架" border />
              <el-checkbox name="collocationName" label="电脑" border />
              <el-checkbox name="collocationName" label="鞋柜" border />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="房源描述" class="w-p-97">
            <el-input
              v-model="upForm.title"
              rows="3"
              type="textarea"
              placeholder="请输入房源描述"
              maxlength="200"
              :show-word-limit="true"
            />
          </el-form-item>
          <el-divider content-position="left">填写出租信息</el-divider>
          <el-form-item label="出租类型" prop="rentType" class="w-p-47">
            <el-select
              v-model="upForm.rentType"
              placeholder="请选择出租类型"
              style="width: 78%"
              @change="rentTypeChange"
            >
              <el-option
                v-for="item in rentTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              />
            </el-select>
            <el-button
              type="primary"
              style="margin-left: 10px"
              @click="splitClickHandel"
              >拆分房源</el-button
            >
          </el-form-item>
          <el-form-item
            label="出租部位"
            prop="roomName"
            class="w-p-47"
            v-if="upForm.rentType === '2'"
          >
            <el-cascader
              :options="rentSplitList"
              :props="cascaderProps"
              clearable
              style="width: 100%"
              @change="upFormSplitChange"
            />
          </el-form-item>
          <el-form-item label="月租金" class="w-p-47" prop="rentPrice">
            <el-input
              v-model="upForm.rentPrice"
              type="number"
              placeholder="请输入月租金"
            >
              <template #append>元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="付款方式" prop="payWay" class="w-p-47">
            <el-select
              v-model="upForm.payWay"
              placeholder="请选择付款方式"
              @change="payWayChange"
              style="width: 100%"
            >
              <el-option
                v-for="item in payWayMap"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-divider content-position="left"
            >房源图片上传(至少三张、且单个大小不得超过6M)
          </el-divider>
          <el-form-item label="房源图片" prop="houseImage">
            <nesi-upload
              @handleFile="handleFile"
              @deleteFile="deleteFile"
              :list="upForm.scans"
            ></nesi-upload>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="upFormDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="upSubmitForm">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="proxyFormDialogVisible"
      title="委托挂牌"
      width="80%"
      top="7vh"
    >
      <el-scrollbar height="500px">
        <el-form
          :model="proxyForm"
          label-width="120px"
          ref="proxyFormRef"
          :rules="proxyFormRules"
          inline
        >
          <el-divider content-position="left">出租信息</el-divider>
          <el-form-item label="企业类型" prop="agentType" class="w-p-47">
            <el-select
              v-model="proxyForm.agentType"
              placeholder="请选择企业类型"
              style="width: 100%"
            >
              <el-option label="代理企业" value="1" />
              <el-option label="托管企业" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="出租类型" prop="rentTypeTemp" class="w-p-47">
            <el-select
              v-model="proxyForm.rentTypeTemp"
              placeholder="请选择出租类型"
              style="width: 100%"
              @change="rentChange"
            >
              <el-option
                v-for="item in proxyRentTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="出租部位"
            prop="splitRoom"
            class="w-p-47"
            v-if="proxyForm.rentTypeTemp === '3'"
          >
            <el-cascader
              :options="rentSplitList"
              v-model="proxyForm.splitRoom"
              :props="cascaderProps"
              clearable
              style="width: 100%"
              @change="splitChange"
            />
          </el-form-item>
          <el-divider content-position="left"
            >企业名称*(请勾选企业再提交)
          </el-divider>
          <el-form-item label="企业名称" class="w-p-47">
            <el-input
              v-model="proxyForm.enterpriseName"
              placeholder="请输入企业名称关键字"
              @keyup.enter="getEnterpriseList"
            />
          </el-form-item>
          <el-form-item class="w-p-47">
            <el-button type="primary" @click="getEnterpriseList"
              >查询
            </el-button>
          </el-form-item>
          <el-table
            ref="multipleTableRef"
            :data="enterpriseData"
            style="width: 100%"
            border
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{
              textAlign: 'center',
              backgroundColor: '#f5f7fa'
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="80" />
            <el-table-column
              property="enterpriseName"
              label="企业名称"
              width="400"
            />
            <el-table-column property="officeAddress" label="办公室地址" />
            <el-table-column property="type" label="企业类型" width="150" />
          </el-table>
          <!-- <el-pagination
            style="margin-top: 10px; text-align: right;"
            background
            layout="total, prev, pager, next"
            :total="1000"
          /> -->
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="proxyFormDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="proxySubmitForm">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="entDialogVisible"
      title="企业管理"
      width="80%"
      top="7vh"
    >
      <el-scrollbar height="500px">
        <el-table
          :data="proxyEnterpriseData"
          style="width: 100%"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <el-table-column
            property="verificationCode"
            label="核验码"
            width="400"
          />
          <el-table-column property="enterpriseName" label="企业名称" />
          <el-table-column label="操作" width="300">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                class="list-btn"
                @click="closeCode(scope.row)"
                >关闭核验码
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="entDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="proxySubmitForm">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="detailDialogVisible" title="详情" width="80%" top="7vh">
      <el-scrollbar height="500px">
        <el-divider content-position="left">房源信息</el-divider>
        <el-row>
          <el-col class="col" :span="12">
            <div class="title">房屋所在城区</div>
            <div class="val">{{ formValues.districtName }}</div>
          </el-col>
          <el-col class="col" :span="12">
            <div class="title">房屋用途</div>
            <div class="val">{{ formValues.housingUse }}</div>
          </el-col>
          <el-col class="col" :span="12">
            <div class="title">套内面积</div>
            <div class="val">{{ formValues.insideArea + ' ㎡' }}</div>
          </el-col>
          <el-col class="col" :span="12">
            <div class="title">建筑面积</div>
            <div class="val">{{ formValues.buildingArea + ' ㎡' }}</div>
          </el-col>
          <el-col class="col" :span="12">
            <div class="title">代理类型</div>
            <div class="val">{{ formValues.type }}</div>
          </el-col>
          <el-col class="col" :span="12">
            <div class="title">出租类型</div>
            <div class="val">{{ formValues.rentType }}</div>
          </el-col>
          <el-col class="col">
            <div class="title">房屋坐落</div>
            <div class="val">{{ formValues.location }}</div>
          </el-col>
          <el-col class="col">
            <div class="title">备注</div>
            <div class="val">{{ formValues.remarks }}</div>
          </el-col>
        </el-row>
        <el-divider content-position="left">出租人信息</el-divider>
        <el-table
          border
          :data="formValues.qlrList"
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <el-table-column prop="qlrxm" label="姓名" :width="120" />
          <el-table-column prop="qllx" label="权利类型" :width="120" />
          <el-table-column prop="qlrlx" label="权利人类型" :width="120" />
          <el-table-column prop="qzh" label="权证号" :width="200" />
          <el-table-column prop="qlrzjlx" label="证件类型" :width="150" />
          <el-table-column prop="qlrzjhm" label="证件号码" />
          <el-table-column prop="qlrlxdh" label="联系电话" :width="150" />
        </el-table>
        <el-divider content-position="left">权属证明</el-divider>
        <el-image
          v-for="item in formValues.images"
          :key="item.url"
          style="width: 150px; height: 150px; margin-left: 10px"
          :preview-src-list="formValues.srcList"
          :src="item.url"
          :initial-index="4"
          fit="cover"
        />
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="detailDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="changeInfoSubmit">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="splitVisible"
      title="关于房源拆分中涉及的部位名称说明"
      width="50%"
      top="15vh"
    >
      <el-scrollbar height="200px">
        <p>
          1）对栋拆分，按"层"来排层号的，请统一将部位名称输入为："一层"、"二层"、"三层"...
        </p>
        <p>
          2）对层拆分，按"套"来排房号的，请统一将部位名称输入为："一层"的输入为"101号房"、"102号房"、"103号房"，"二层"的输入为"201号房"、"202号房"、"203号房"...
        </p>
        <p>
          3）对套拆分，按"间"来排室号的，请统一将部位名称输入为："1号室"、"2号室"、"3号室"...
        </p>
        <p>4）请遵照房源户型进行拆分, 三室两厅房屋最多只能拆三间房进行租赁</p>
        <p style="color: red">
          房源拆分提示：房源拆分请谨慎拆分，房间拆分后任何变更修改都需要走人工审核，由后台人工处理。
        </p>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="splitVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSplitOk">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import NesiUpload from '@/components/NesiUpload/index.vue'
import { onActivated, ref, defineComponent, reactive } from 'vue'
import {
  getOwnHouse,
  openCheckCode,
  closeCheckCode,
  getHouseById,
  getSubleaseHouse,
  ownHouseCheck,
  houseUpShelves,
  getEnterpriseInfo,
  savePersonAgentHouse,
  queryPersonAgentHouse,
  delOwnerHouse
} from '@/api/user-house'
import { getCommunityList } from '@/api/sys'
import { getRentSelectOptions, getRentBwSelectOptions } from '@/utils/split'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import QrcodeVue from 'qrcode.vue'

defineComponent({
  NesiUserCommon,
  QrcodeVue,
  NesiUpload
})
const router = useRouter()

// 跳转新增房源
const addHouse = () => {
  router.push('/user/house/query')
}

const cascaderProps = ref({
  checkStrictly: true
})

const tableData = ref([])
const getOwnHouseList = async () => {
  const resp = await getOwnHouse()
  tableData.value = handleList(resp)
}

const handleList = (list) => {
  return (
    list.length &&
    list.map((item) => {
      let status = ''
      let buttons = []
      if (item.govVerification == 2 || item.fwbh) {
        if (item.open === 1) {
          status = '核验码已开启'
          buttons = [1, 2, 3, 6]
        } else {
          status = '核验码已关闭'
          buttons = [4]
        }
        if (item.agent) {
          buttons.push(5)
        }
      } else if (item.govVerification == 1) {
        buttons = [9, 7]
        status = '审核中' // 撤销，查看房源
      } else if (item.govVerification == 3) {
        status = '已回退'
        buttons = [7, 8, 9, 10] // 撤销，查看原因，查看房源，变更信息
      } else if (item.govVerification == 4) {
        status = '撤销申请成功'
        buttons = [] //
      } else if (item.govVerification == 5) {
        status = '撤销申请失败'
        buttons = [9, 8] // 撤销，查看原因
      } else {
        status = '审核不通过'
        buttons = [9, 8, 7] // 撤销，查看原因，查看房源
      }

      return {
        ...item,
        type: '1', // 个人房源
        status,
        buttons
      }
    })
  )
}

const btnMap = ref([
  { id: 1, text: '关闭核验码', method: 'closeCode' },
  { id: 2, text: '自主挂牌', method: 'upHouse' },
  { id: 3, text: '委托挂牌', method: 'proxyHouse' },
  { id: 4, text: '开启核验码', method: 'openCode' },
  { id: 5, text: '查看企业', method: 'getEnterprise' },
  { id: 6, text: '房源拆分', method: 'splitHouse' },
  { id: 7, text: '查看房源', method: 'seeHouse' },
  { id: 8, text: '查看原因', method: 'seeReson' },
  { id: 9, text: '撤销', method: 'delHouse' },
  { id: 10, text: '变更信息', method: 'changeInfo' }
])

const transMethod = (item, method) => {
  switch (method) {
    case 'upHouse':
      upHouse(item)
      break
    case 'closeCode':
      closeCode(item)
      break
    case 'proxyHouse':
      proxyHouse(item)
      break
    case 'openCode':
      openCode(item)
      break
    case 'getEnterprise':
      getEnterprise(item)
      break
    case 'delHouse':
      delHouse(item)
      break
    case 'seeHouse':
      seeHouse(item)
      break
    case 'changeInfo':
      changeInfo(item)
      break
    case 'seeReson':
      seeReson(item)
      break
    case 'splitHouse':
      router.push({
        path: '/user/split',
        query: {
          id: item.id,
          fwqzh: item.fwqzh,
          fwbh: item.fwbh
        }
      })
      break
    default:
      return ''
  }
}

// 房源详情
const detailDialogVisible = ref(false)
const formValues = reactive({
  districtName: '',
  housingUse: '',
  insideArea: '',
  buildingArea: '',
  location: '',
  remarks: '',
  type: '',
  rentType: '',
  qlrList: [],
  images: [],
  srcList: [],
  changeList: []
})
const seeHouse = async (item) => {
  detailDialogVisible.value = true
  const resp = await getHouseById({ houseId: item.id })
  const minioUrl = resp.minioUrl
  formValues.districtName = resp.houseIndfo.districtName
  formValues.housingUse = resp.houseIndfo.housingUse
  formValues.insideArea = resp.houseIndfo.insideArea
  formValues.buildingArea = resp.houseIndfo.buildingArea
  formValues.location = resp.houseIndfo.location
  formValues.remarks = resp.houseIndfo.remarks
  formValues.type = getHouseType(resp.houseIndfo)
  formValues.rentType = getRentType(resp.houseIndfo)
  if (resp.houseIndfo.propertyOwnerInfo) {
    formValues.qlrList = resp.houseIndfo.propertyOwnerInfo.map((item) => {
      if (!item.qzh) {
        item.qzh = item.wqhth
      }
      return item
    })
  } else {
    formValues.qlrList = []
  }
  // formValues.qlrList = resp.houseIndfo.propertyOwnerInfo
  //   ? JSON.parse(resp.houseIndfo.propertyOwnerInfo)
  //   : []
  const images = resp.houseIndfo.propertyOwnerScan
    ? JSON.parse(resp.houseIndfo.propertyOwnerScan)
    : []

  formValues.images = images.map((item) => {
    return {
      ...item,
      url: minioUrl + item.smjsj
    }
  })

  formValues.srcList = formValues.images.map((item) => {
    return item.url
  })
}

const changeInfo = (item) => {
  router.push({ path: '/user/house/handle', query: { id: item.id } })
}

const changeInfoSubmit = () => {
  if (!isChange.value) {
    detailDialogVisible.value = false
  } else {
    // 变更信息
  }
}

const seeReson = (item) => {
  ElMessageBox.alert(item.statusMsg, '温馨提示', {
    confirmButtonText: '确定'
  })
}

const getRentType = (item) => {
  return item.rentType === 1 ? '整租' : '分租'
}
const getHouseType = (item) => {
  let typeCn = ''
  switch (item.type) {
    case 3:
    case 6:
    case 7:
    case 11:
      typeCn = '自持'
      break
    case 4:
    case 8:
    case 10:
      typeCn = '代理'
      break
    case 9:
    case 12:
      typeCn = '托管'
      break
    case 14:
      typeCn = '转租代理'
      break
    case 15:
      if (item.upStatus == 4) {
        typeCn = '托管待备案'
      } else if (item.up_status == 1) {
        typeCn = '核验房源待上架'
      } else if (item.up_status == 5) {
        typeCn = '托管已备案'
      } else if (item.up_status == 6) {
        typeCn = '托管待注销'
      } else if (item.up_status == 7) {
        typeCn = '托管已注销'
      } else if (item.up_status == 8) {
        typeCn = '托管注销拒绝'
      }
      break
    case 17:
      typeCn = '个人委托房源'
      break
    case 18:
      typeCn = '个人托管房源'
      break
    default:
      typeCn = '其他'
  }
  return typeCn
}

const delHouse = (item) => {
  ElMessageBox.prompt('撤销理由：', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
    inputErrorMessage: '撤销房源理由不能为空'
  })
    .then(async ({ value }) => {
      const resp = await delOwnerHouse({
        houseId: item.id,
        reason: value,
        type: '1'
      })
      ElMessage.success(resp)
      getOwnHouseList()
    })
    .catch(() => {})
}

const rentTypeChange = (item) => {
  if (1) {
    upForm.roomName = ''
  }
}

const openCode = (item) => {
  ElMessageBox.confirm('是否开启房源核验码？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      if (item.gov_house_number) {
        await openCheckCode({ checkCode: item.gov_house_number })
      } else {
        await ownHouseCheck({
          houseNumber: item.fwbh,
          warrantNo: item.fwqzh
        })
      }
      ElMessage.success('操作成功')
      getOwnHouseList()
    })
    .catch(() => {})
}

const closeCode = async (item) => {
  ElMessageBox.confirm('是否关闭房源核验码？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await closeCheckCode({
        checkCode: item.gov_house_number || item.verificationCode
      })
      ElMessage.success('操作成功')
      if (entDialogVisible.value) {
        entDialogVisible.value = false
      }
      getOwnHouseList()
    })
    .catch(() => {})
}

// 房源上架===============开始============
const upFormDialogVisible = ref(false)
const upForm = reactive({
  communityId: '',
  communityName: '',
  buildingNo: '',
  roomNo: '',

  startFloor: '',
  terminationFloor: '',
  buildingTotalFloor: '',

  // 厅室卫
  hallNum: '',
  roomNum: '',
  toiletNum: '',
  houseId: '',

  houseTags: '',
  houseTagsArr: [], // 数据展示用
  landlordRequest: '',
  landlordRequestArr: [], // 数据展示用
  collocationName: '',
  collocationNameArr: [], // 数据展示用

  parentNum: '',
  payWay: '',
  payWayName: '',
  rentPrice: '',
  rentType: '',

  // 拆分需上传出租部位
  roomId: '',
  roomName: '',
  roomSpace: '',
  roomType: '',

  // 图片附件
  scans: [],
  situation: '',
  situationName: '',
  title: '',
  toward: '',
  towardName: '',
  type: '1'
})

const validatorImage = (rule, value, callback) => {
  if (!upForm.scans.length) {
    callback(new Error('请上传房源图片'))
  } else if (rule.minLength && upForm.scans.length < rule.minLength) {
    callback(new Error('请上传至少3张房源图片'))
  } else {
    callback()
  }
}
const validateZero = (rule, value, callback) => {
  if (value === '0' || value === 0) {
    callback(new Error('该选项不能为0'))
  } else {
    callback()
  }
}

const upFormRules = reactive({
  communityName: [
    { required: true, message: '小区名称不能为空', trigger: 'blur' }
  ],
  buildingNo: [
    { required: true, message: '房屋楼栋不能为空', trigger: 'blur' }
  ],
  roomNo: [{ required: true, message: '房屋室号不能为空', trigger: 'blur' }],
  startFloor: [
    { required: true, message: '起始楼层不能为空', trigger: 'blur' }
  ],
  terminationFloor: [
    { required: true, message: '终止楼层不能为空', trigger: 'blur' }
  ],
  buildingTotalFloor: [
    { required: true, message: '总楼层不能为空', trigger: 'blur' }
  ],
  hallNum: [
    { required: true, message: '房屋厅数量不能为空', trigger: 'blur' },
    { validator: validateZero, message: '房屋厅数量不能为0', trigger: 'blur' }
  ],
  roomNum: [
    { required: true, message: '房屋室数量不能为空', trigger: 'blur' },
    { validator: validateZero, message: '房屋室数量不能为0', trigger: 'blur' }
  ],
  toiletNum: [
    { required: true, message: '房屋卫生间数量不能为空', trigger: 'blur' },
    {
      validator: validateZero,
      message: '房屋卫生间数量不能为0',
      trigger: 'blur'
    }
  ],
  situation: [{ required: true, message: '装修类型不能为空', trigger: 'blur' }],
  toward: [{ required: true, message: '朝向类型不能为空', trigger: 'blur' }],
  rentType: [{ required: true, message: '出租类型不能为空', trigger: 'blur' }],
  roomName: [{ required: true, message: '出租部位不能为空', trigger: 'blur' }],
  rentPrice: [{ required: true, message: '月租金不能为空', trigger: 'blur' }],
  payWay: [{ required: true, message: '付款方式不能为空', trigger: 'blur' }],
  houseImage: [
    {
      validator: validatorImage,
      trigger: 'blur',
      minLength: 3
    }
  ]
})

const situationMap = [
  { label: '毛坯', value: '1' },
  { label: '普通装修', value: '2' },
  { label: '精装修', value: '3' },
  { label: '豪华装修', value: '4' }
]

const situationChange = (value) => {
  upForm.situationName = situationMap.find((item) => item.value === value).label
}

const towardMap = [
  { label: '东', value: '1' },
  { label: '南', value: '2' },
  { label: '西', value: '3' },
  { label: '北', value: '4' },
  { label: '东南', value: '5' },
  { label: '西南', value: '6' },
  { label: '东北', value: '7' },
  { label: '西北', value: '8' },
  { label: '南北', value: '9' },
  { label: '东西', value: '10' },
  { label: '暂无朝向', value: '11' }
]

const towardChange = (value) => {
  upForm.towardName = towardMap.find((item) => item.value === value).label
}

const payWayMap = [
  { value: '3', label: '付一押一' },
  { value: '8', label: '付一押二' },
  { value: '10', label: '付一押三' },
  { value: '2', label: '付二押一' },
  { value: '1', label: '付三押一' },
  { value: '4', label: '付三押二' },
  { value: '11', label: '月付' },
  { value: '5', label: '半年付' },
  { value: '9', label: '季付' },
  { value: '6', label: '整年付' },
  { value: '7', label: '面议' }
]

const splitVisible = ref(false)
const splitClickHandel = () => {
  splitVisible.value = true
}

const handleSplitOk = () => {
  router.push({
    path: '/user/split',
    query: {
      id: upHouseItem.value.id,
      fwqzh: upHouseItem.value.fwqzh,
      fwbh: upHouseItem.value.fwbh
    }
  })
}

const payWayChange = (value) => {
  upForm.payWayName = payWayMap.find((item) => item.value === value).label
}

const handleFile = (value) => {
  upForm.scans.push({
    name: value.name,
    smjmc: value.name,
    smjsj: value.path,
    uid: value.uid,
    url: value.url
  })
}

const deleteFile = (value) => {
  upForm.scans = upForm.scans.filter((item) => item.uid !== value.uid)
}

const minioUrl = ref('')
const rentTypeList = ref([])
const proxyRentTypeList = ref([])
const rentSplitList = ref([])
const upHouseItem = ref(null)
const upHouse = async (item) => {
  upHouseItem.value = item
  upFormDialogVisible.value = true
  // 查询小区信息
  const resp = await getHouseById({ houseId: item.id })

  const houseInfo = resp.houseIndfo
  minioUrl.value = resp.minioUrl

  /**
   * 小区名称，如果后台返回的小区ID有值，则不用再去查小区信息列表，如果没有值，且小区名称不为空，则
   * 根据小区名称精准查询小区信息，如果有饭会友结果，则直接赋值小区ID，否则查询小区信息列表，让用户
   * 自己选择
   */
  if (houseInfo.communityId) {
    upForm.communityName = houseInfo.communityName
    upForm.communityId = houseInfo.communityId
  } else {
    if (houseInfo.communityName) {
      getCommutyListByName(houseInfo.communityName)
    }
  }

  // 房源门牌
  upForm.buildingNo = houseInfo.buildingNo
    ? houseInfo.buildingNo.split('栋')[0]
    : ''
  upForm.roomNo = houseInfo.roomNo

  // 楼层
  upForm.startFloor = houseInfo.startFloor
  upForm.terminationFloor = houseInfo.terminationFloor
  upForm.buildingTotalFloor = houseInfo.buildingTotalFloor

  // 室厅卫
  upForm.hallNum = houseInfo.hallNum
  upForm.roomNum = houseInfo.roomNum
  upForm.toiletNum = houseInfo.toiletNum

  if (houseInfo.houseTags) {
    upForm.houseTags = houseInfo.houseTags
    upForm.houseTagsArr = houseInfo.houseTags
      ? houseInfo.houseTags.split(',')
      : []
  }

  if (houseInfo.landlordRequest) {
    upForm.landlordRequest = houseInfo.landlordRequest
    upForm.landlordRequestArr = houseInfo.landlordRequest
      ? houseInfo.landlordRequest.split(',')
      : []
  }

  if (houseInfo.collocationName) {
    upForm.collocationName = houseInfo.collocationName
    upForm.collocationNameArr = houseInfo.collocationName
      ? houseInfo.collocationName.split(',')
      : []
  }

  upForm.title = houseInfo.title

  upForm.rentPrice = houseInfo.rentPrice ? houseInfo.rentPrice : ''
  upForm.payWay = houseInfo.payWay ? houseInfo.payWay.toString() : ''
  upForm.payWayName = upForm.payWay.toString()
    ? payWayMap.find((item) => item.value === houseInfo.payWay.toString()).label
    : ''

  upForm.toward = houseInfo.toward ? houseInfo.toward.toString() : ''
  upForm.towardName = houseInfo.towardName

  upForm.situation = houseInfo.situation ? houseInfo.situation.toString() : ''
  upForm.situationName = houseInfo.situationName
  if (!upForm.situationName) {
    situationChange(upForm.situation)
  }
  upForm.houseId = houseInfo.houseId

  if (houseInfo.houseOther) {
    const imageList = JSON.parse(houseInfo.houseOther)
    if (imageList && imageList.length) {
      upForm.scans = imageList.map((imageItem) => {
        const tempUrl = imageItem.src || imageItem.smjsj
        const url =
          tempUrl.indexOf('http') != -1 ? tempUrl : minioUrl.value + tempUrl
        return {
          name: imageItem.filename,
          uid: new Date().getTime() + '_' + imageItem.filename,
          url: url,
          ...imageItem
        }
      })
    } else {
      upForm.scans = []
    }
  } else {
    upForm.scans = []
  }

  // 出租类型
  getRentTypes(houseInfo, item)
}

const originRoomList = ref([])
const originUpRoomList = ref([])
const getRentTypes = async (houseInfo, item) => {
  const resp = await getSubleaseHouse({
    houseId: houseInfo.houseId,
    houseNum: houseInfo.houseNumber || item.fwbh,
    parentRoomNum: ''
  })
  originRoomList.value = resp.subleaseEntitys
  originUpRoomList.value = resp.upHouses
  const option = getRentSelectOptions(
    originRoomList.value,
    originUpRoomList.value,
    houseInfo.lesseeRoomNumber,
    houseInfo.sublease
  )
  const allRentText = option.upHouseFlag ? '整租(已上架)' : '整租'
  rentTypeList.value = [
    { label: allRentText, value: '1', disabled: option.allRentDisable },
    { label: '分租', value: '2', disabled: option.splitRentDisable }
  ]
  proxyRentTypeList.value = [
    { label: allRentText, value: '1', disabled: option.allRentDisable },
    { label: '分租', value: '2', disabled: option.splitRentDisable },
    { label: '部分出租', value: '3', disabled: option.splitRentDisable }
  ]
  rentSplitList.value = getRentBwSelectOptions(
    originRoomList.value,
    houseInfo.lesseeRoomNumber,
    houseInfo.sublease,
    originUpRoomList.value
  )
}

const getCommutyListByName = async (communityName) => {
  const resp = await getCommunityList({ communityName, type: 1 })
  if (resp.length) {
    upForm.communityName = resp[0].communityName
    upForm.communityId = resp[0].communityId
  } else {
    getAllCommutityList()
  }
}

const getAllCommutityList = async () => {
  if (!communityData.value.length) {
    communityData.value = await getCommunityList({})
  }
}

const communityData = ref([])

const querySearch = (queryString, cb) => {
  const results = queryString
    ? communityData.value.filter(createFilter(queryString))
    : communityData.value
  // call callback function to return suggestions
  cb(results)
}

const createFilter = (queryString) => {
  return (community) => {
    return community.name.indexOf(queryString) === 0
  }
}

const handleCommutySelect = (value) => {
  upForm.communityId = value.id
}

const upFormRef = ref(null)
const upSubmitForm = () => {
  upFormRef.value.validate(async (valid) => {
    if (!valid) return false
    upForm.houseTags = upForm.houseTagsArr.join(',')
    upForm.landlordRequest = upForm.landlordRequestArr.join(',')
    upForm.collocationName = upForm.collocationNameArr.join(',')
    const resp = await houseUpShelves(upForm)
    ElMessage.success(resp)
    upFormDialogVisible.value = false
    getOwnHouseList()
  })
}

// 房源上架===============结束============

// 企业委托
const proxyFormDialogVisible = ref(false)
const proxyForm = reactive({
  agentType: '',
  houseId: '',
  orgs: [],
  rentTypeTemp: '',
  rentType: '',
  roomName: '',
  splitRoom: '',
  roomNum: '',
  roomSpace: 0,
  roomType: '',
  zlbw: '',
  enterpriseName: ''
})

const proxyFormRef = ref(null)
const proxyFormRules = reactive({
  agentType: [{ required: true, message: '企业类型不能为空', trigger: 'blur' }],
  rentTypeTemp: [
    { required: true, message: '出租类型不能为空', trigger: 'blur' }
  ],
  splitRoom: [{ required: true, message: '出租部位不能为空', trigger: 'blur' }]
})

const proxySubmitForm = () => {
  proxyFormRef.value.validate(async (valid) => {
    if (!valid) return
    if (!proxyForm.orgs.length) {
      ElMessage.error('请输入企业名称关键字查询后，选择委托企业')
      return
    }
    const resp = await savePersonAgentHouse(proxyForm)
    ElMessage.success(resp)
    proxyFormDialogVisible.value = false
    getOwnHouseList()
  })
}

const proxyHouse = async (item) => {
  proxyFormDialogVisible.value = true
  // 查询小区信息
  const resp = await getHouseById({ houseId: item.id })
  const houseInfo = resp.houseIndfo
  minioUrl.value = resp.minioUrl
  proxyForm.houseId = houseInfo.houseId
  // 出租类型
  getRentTypes(houseInfo)
}

const getEnterpriseList = async () => {
  if (proxyForm.enterpriseName) {
    const resp = await getEnterpriseInfo({
      enterpriseName: proxyForm.enterpriseName
    })
    if (resp.length) {
      enterpriseData.value = resp.map((item) => {
        return {
          ...item,
          type: '企业'
        }
      })
    }
  } else {
    enterpriseData.value = []
  }
}

const splitChange = (value) => {
  proxyForm.roomNum = value[0].split('|')[0]
  proxyForm.roomSpace = value[0].split('|')[1]
  proxyForm.roomType = value[0].split('|')[2]
  proxyForm.roomName = value[0].split('|')[3]
  proxyForm.zlbw = value[0].split('|')[3]
}

const upFormSplitChange = (value) => {
  upForm.roomId = value[0].split('|')[0]
  upForm.roomSpace = value[0].split('|')[1]
  upForm.roomType = value[0].split('|')[2]
  upForm.roomName = value[0].split('|')[3]
}

const handleSelectionChange = (value) => {
  if (value.length) {
    proxyForm.orgs = value.map((item) => {
      return {
        orgName: item.enterpriseName,
        orgId: item.enterpriseNo
      }
    })
  }
}

const rentChange = (value) => {
  if (value === '1' || value === '2') {
    ;[
      proxyForm.roomNum,
      proxyForm.roomSpace,
      proxyForm.roomType,
      proxyForm.roomName,
      proxyForm.zlbw
    ] = ['', '', '', '', '']
  }
  proxyForm.rentType = proxyForm.rentTypeTemp
}
// 用来存储查询到的委托企业
const enterpriseData = ref([])

// 用来存储某个房源已委托的企业列表
const proxyEnterpriseData = ref([])
const entDialogVisible = ref(false)
const getEnterprise = async (item) => {
  entDialogVisible.value = true
  const resp = await queryPersonAgentHouse({
    checkCode: item.gov_house_number
  })
  proxyEnterpriseData.value = resp
}

// 缓存路由情况下使用，则每次都进到该钩子
onActivated(() => {
  getOwnHouseList()
  getAllCommutityList()
})
</script>

<style lang="scss" scoped>
.w-p-47 {
  width: 47%;
}

.w-p-97 {
  width: 97%;
}

.col {
  border: 1px solid #e6e9f0;
  height: 55px;
  line-height: 55px;
  display: flex;

  div {
    padding-left: 20px;
  }
  .title {
    min-width: 150px;
    background-color: #f5f7fa;
    color: #1f2e4d;
  }
  .val {
    flex: 1;
    border-left: 1px solid #e6e9f0;
    color: #3d4966;
  }
}

p {
  padding: 5px 0;
}
</style>
